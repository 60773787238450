<template>
  <div></div>
</template>
<script>
export default {
  mounted() {
    console.log(this.$route)
    let target = this.$route.query.target
    const token = this.$store.state.user.auth.accessToken
    if (target) {
      target += target.includes('?') ? '&' : '?'
      target += `token=${token}`
    }
    window.location.replace(target)
  },
}
</script>
